let cdnjs = {
    "headroom": "https://cdn.jsdelivr.net/npm/headroom.js@0.9.4/dist/headroom.min.js",
    "touchswipe": "https://cdn.jsdelivr.net/npm/jquery-touchswipe@1.6.19/jquery.touchSwipe.min.js",
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "cookieconsent": "https://packages.newlogic.cz/newlogic-cookieconsent/1.0.0/js/newlogic-cookieconsent.min.js",
    "flickity": "https://cdn.jsdelivr.net/npm/flickity@2.2.0/dist/flickity.pkgd.min.js",
    "googlemap": "https://maps.googleapis.com/maps/api/js?v=3.35&key=APIKEY",
    "lightgallery": "https://cdn.jsdelivr.net/npm/lightgallery@1.6.11/dist/js/lightgallery-all.min.js",
    "footable": "https://cdnjs.cloudflare.com/ajax/libs/jquery-footable/3.1.6/footable.min.js",
    "csshint": "https://cdn.jsdelivr.net/npm/hint.css@2.5.1/hint.min.css",
    "noui": "https://cdnjs.cloudflare.com/ajax/libs/noUiSlider/10.1.0/nouislider.min.js",
    "autoNumeric": "https://cdn.jsdelivr.net/npm/autonumeric@4.2.15/dist/autoNumeric.min.js",
    "stickyfill": "https://cdnjs.cloudflare.com/ajax/libs/stickyfill/2.1.0/stickyfill.min.js",
};