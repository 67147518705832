$.lui("comp",".comp_gallery_single",function (selector) {
    $.lib_flickity(function(){
       let carousel = selector.find("[data-carousel]"),
           thumbs = selector.find("[data-thumbs]");

       carousel.flickity({
           imagesLoaded: false,
           lazyLoad: true,
           percentPosition: false,
           setGallerySize: true,
           pageDots: false,
           wrapAround: false,
           contain: true,
           prevNextButtons: true,
           cellAlign: "left",
           draggable: true,
           accessibility: true,
           on: {
               change: function (index) {
                   let el = thumbs.find(".elm_nav_item").eq(index);
                   thumbs.find(".state--active").removeClass("state--active");
                   el.addClass("state--active");
                   let col_width = el.closest(".col").outerWidth();
                   thumbs.animate({
                       scrollLeft: col_width * index
                   },300);
               }
           }
       });

       selector.on("click", ".elm_nav_item", function () {
           carousel.flickity( 'select', $(this).data("id"));
       });

        let isDown = false;
        let startX;
        let scrollLeft;

        thumbs.on({
            mousedown: function(e){
                isDown = true;
                thumbs.addClass('is--dragging');
                startX = e.pageX - thumbs.get(0).offsetLeft;
                scrollLeft = thumbs.get(0).scrollLeft;
            },
            mouseleave: function () {
                isDown = false;
                thumbs.removeClass('is--dragging');
            },
            mouseup: function () {
                isDown = false;
                thumbs.removeClass('is--dragging');
            },
            mousemove: function (e) {
                if(!isDown) return;
                e.preventDefault();
                const x = e.pageX - thumbs.get(0).offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                thumbs.get(0).scrollLeft = scrollLeft - walk;
            },
            contextmenu: function (e) {
                e.preventDefault();
                e.stopPropagation();
            }
        });
    });
});