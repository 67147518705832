$.lui("comp",".comp_apartments",function (selector) {
    let visual = selector.find(".wrp_comp_selector"),
        visual_floors = visual.find("[data-floors]").find("[data-name]"),
        visual_tooltips = visual.find("[data-tooltips]").find("[data-name]"),
        sublevel = selector.find(".wrp_comp_sublevel");

    selector.off("click");

    visual_floors.each(function (index) {
        let el = $(this);
        el.off('mouseenter');
        el.off('mouseleave');
        el.off('click');

        el.on("mouseenter",function () {
            visual_floors.removeClass("state--hover");
            visual_tooltips.removeClass("state--hover");
            el.addClass("state--hover");
            visual_tooltips.eq(index).addClass("state--hover");
        });
        el.on("mouseleave",function () {
           el.removeClass("state--hover");
            visual_tooltips.eq(index).removeClass("state--hover");
        });

        el.on("click",function () {
            visual_floors.removeClass("state--hover");
            visual_floors.removeClass("state--active");
            visual_tooltips.removeClass("state--hover state--active");
            el.addClass("state--active");
            visual_tooltips.eq(index).addClass("state--active");

            let url = el.find('[data-floor-ajax]').data("floor-ajax");
            
            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    ScrollReveal().sync();
                    $('.part_selector_floor').removeClass('display--none').anchor_anim(500);
                    $('.part_selector_apartment').addClass('display--none');
                });
            });
        })
    });

    selector.on("click","[data-show-sublevel]",function () {
        selector.find("[data-show-sublevel]").attr("disabled","disabled");
        sublevel.slideDown(function () {
            sublevel.anchor_anim(500);
        });
    });
});

