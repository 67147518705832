$.lui("part",".part_selector_floor",function (selector) {
    let visual = selector.find(".wrp_part_floorplan"),
        visual_floors = visual.find("[data-name]"),
        visual_tooltips = selector.find(".wrp_part_details").find("[data-name]");

    visual_floors.each(function (index) {
        let el = $(this);
        el.off("click");
        el.off("mouseenter");

        // if(el.hasClass("state--sold") || el.hasClass("state--reserved")) {
        //     return;
        // }

        el.one("mouseenter click",function () {
            selector.find(".elm_hint").removeClass("state--active");
        });

        el.on("mouseenter click",function () {
            visual_floors.removeClass("state--active");
            visual_tooltips.removeClass("state--active");
            el.addClass("state--active");
            visual_tooltips.filter(function (elm) {
                return $(visual_tooltips[elm]).data("name") === el.data("name");
            }).addClass("state--active");
        });

        el.on("click",function () {
            let url = el.attr('data-apartment-ajax');
            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                selector.find("[data-show-sublevel]").hide();
                fn_ajaxHandler(payload, function () {
                    ScrollReveal().sync();
                    $('.part_selector_apartment').removeClass('display--none').anchor_anim(500);
                });
            });
        })
    });
});