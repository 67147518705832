$.lui("part", ".part_form_filter", function(selector) {
    let range = selector.find(".part_ui_range");
    if (range.length) {
        range.each(function () {
            let elm = $(this);
            $.importScript(cdnjs.autoNumeric, function () {
                $.importScript(cdnjs.noui, function () {
                    elm.lib_noui({
                        elm_min: elm.find(`[data-range-input="min"]`),
                        elm_max: elm.find(`[data-range-input="max"]`),
                        elm_min_hidden: elm.find(`[data-range-input="min"]`).next(),
                        elm_max_hidden: elm.find(`[data-range-input="max"]`).next(),
                        unit: " " + elm.data("unit")
                    });

                    elm.on("change", `[data-range-input="min"]`, function () {
                        let min = $(this).val();
                        let max = elm.find(`[data-range-input="max"]`).val();
                    });
                    elm.on("change", `[data-range-input="max"]`, function () {
                        let min = elm.find(`[data-range-input="min"]`).val();
                        let max = $(this).val();
                    });
                });
            });
        });
    }
});