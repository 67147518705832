$.lui("comp",".comp_head_map",function (selector) {
    selector.on("click","[data-scroll]",function () {
        let offset = 0;

        if(win.width() > 960) {
            offset = 70;
        }
        $('html, body').animate({
            scrollTop: selector.next("section, div").offset().top - offset
        }, 500);
    });

    let map_element = selector.find("[data-google-map]");

    $.importScript(cdnjs.googlemap.replace("APIKEY", map_element.data("google-map")), function(){
        let coords = map_element.data('coords').split(/[\s,]+/),
            marker = map_element.data("marker"),
            markers_temp = [],
            bounds = new google.maps.LatLngBounds(),
            position = new google.maps.LatLng(coords[0], coords[1]);

        let map = new google.maps.Map(map_element[0], {
            zoom: 14,
            center: {
                lat: parseFloat(coords[0]), lng: parseFloat(coords[1])
            },
            zoomControl: true,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                position: google.maps.ControlPosition.RIGHT_TOP
            },
            scaleControl: false,
            streetViewControl: true,
            rotateControl: false,
            fullscreenControl: false,
        });

        let image = {
            url: marker,
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(40, 112),
            size: new google.maps.Size(80, 112),
        };

        let map_marker = new google.maps.Marker({
            position: position,
            map: map,
            icon: image
        });

        bounds.extend(position);

        google.maps.event.addListener(map_marker, 'click', function(){
            window.open(map_element.data("marker-url"), '_blank');
        });

        let markers = map_element.data("markers");
        if (markers.length > 0) {
            let infoWindow = new google.maps.InfoWindow({
                content: "",
                maxWidth: 266
            });

            for (let i = 0; i < markers.length; i++) {
                let marker = markers[i],
                    coords = marker.coords.split(/[\s,]+/),
                    position = new google.maps.LatLng(coords[0], coords[1]),
                    image = {
                        url: marker.icon,
                        scaledSize: new google.maps.Size(43, 55),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(22, 55)
                    },
                    icon = new google.maps.Marker({
                        position: position,
                        map: map,
                        icon: image,
                        title: marker.name,
                        zIndex: marker.zIndex,
                        about: marker.about,
                        img: marker.img
                    });

                markers_temp.push(icon);
                bounds.extend(position);

                function showInfo(marker) {
                    return function () {
                        let img = "";
                        if (marker.img) {
                            img = "<div class='elm_image' data-ratio='8/3'><img src='" + marker.img + "'/></div>";
                        }
                        console.log(img);
                        let content = "<div class='part_ui_info'>" + img + "<div class='elm_row'><h4>" + marker.title + "</h4><div class='elm_info'>" + marker.about + "</div></div>";
                        map.panTo(marker.position);
                        infoWindow.setContent(content);
                        infoWindow.open(map, marker);
                    }
                }

                google.maps.event.addListener(icon, 'click', showInfo(icon));

                google.maps.event.addListener(infoWindow, 'domready', function () {
                    $('.part_ui_info').closest('.gm-style-iw').parent().addClass('gm-custom-iw');
                });
            }
        }

        // map.fitBounds(bounds);
    });
});