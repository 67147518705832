$.lui("comp",".comp_head",function (selector) {
    selector.on("click","[data-scroll]",function () {
        let offset = 0;

        if(win.width() > 960) {
            offset = 70;
        }
        $('html, body').animate({
            scrollTop: selector.next("section, div").offset().top - offset
        }, 500);

    })
});