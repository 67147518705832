$.lui("comp",".comp_pricelist",function (selector) {
    let filter = selector.find(".part_form_filter"),
        table = selector.find(".part_ui_table table"),
        columns = table.data("columns"),
        rows = table.data("rows"),
        top = selector.find("#filter-above");

    $.importStyle(cdnjs.csshint);
    $.importScript(cdnjs.footable,function () {
        let ft = FooTable.init(table, {
            "columns": $.getJSON(columns),
            "rows": $.getJSON(rows),
            "paging": {
                "enabled": true,
                "size": 30,
                "limit": 5,
                "countFormat": ""
            }
        });

        (function($, F){
            F.Row.extend("$create", function(){
                this._super();
                let values = this.val();
                this.$el.attr("data-dialog-tr", values["dialog_detail"]);
                this.cells[8].$el.attr("data-lib-dialog", values["dialog_detail"]);                               
                //this.cells[5].$el.attr("data-sort-value", parseFloat(values["label_flat_size"].replace('m<sup>2</sup>', '')));
                this.cells[5].$el.addClass("type--size");
                if(!this.cells[7].$el.children("span").hasClass("text--color-success")){
                    let row = this.$el;
                    row.addClass("state--disabled");
                }
            });
            F.Paging.extend("postdraw",function () {
                this._super();
                //$(this.$pagination).find("a").attr("data-no-swup",true);
                $(this.$pagination).find("a").attr("href",'#top');
            });
            F.Paging.extend("_onPageClicked", function (e) {
                this._super(e);
                //top.anchor_anim(300);
            });
        })(jQuery, FooTable);

        table.on({
            "postdraw.ft.table": function(e, ft){
                setTimeout(function(){
                    table.removeClass("footable--loading").parent().css("height","auto");

                    let next = selector.find('[data-table-nav="next"]'),
                        prev = selector.find('[data-table-nav="prev"]');

                    if (selector.find('.footable-paging').find('[data-page="next"]').hasClass("disabled")) {
                        next.attr("disabled", "true");
                    } else {
                        next.removeAttr("disabled");
                    }

                    if (selector.find('.footable-paging').find('[data-page="prev"]').hasClass("disabled")) {
                        prev.attr("disabled", "true");
                    } else {
                        prev.removeAttr("disabled");
                    }
                },100);
            },
            "init.ft.table": function(e, ft){

            },
            "expand.ft.row": function (e, ft, row) {
                if(win.width() > 991) {
                    e.preventDefault();
                }
            }
        });

        table.on("click","[data-dialog-tr]",function () {
            if (win.width() > 991) {
                let el = $(this);
                $.ajax({
                    dataType: "json",
                    data: {ajax: +new Date},
                    url: el.data('dialog-tr')
                }).done(function(data) {
                    lui_dialog.open(data.dialog);
                });
            }
        });

        function filter_update() {
            let formData = filter.serialize();
            window.history.replaceState(null, null, '?'+formData);
            $.ajax({
                method: 'get',
                data: formData,
                dataType: 'json',
                url: window.location.href
            }).done(function(data) {
                table.parent().css("height",table.parent().height());
                if (!table.hasClass("footable--loading")) {
                    table.addClass("footable--loading");
                    ft.rows.load(data);
                }
            });
        }

        filter.find("input").on("change", function(){
            filter_update();
        });

        filter.find("[data-clear-filter]").on("click", function(e){
            e.preventDefault();
            $('#filter')[0].reset();
            filter_update();
            window.history.replaceState(null, null, '?');
            filter.find('[data-range-slider]').each(function(){                
                // $(this)[0].noUiSlider.set([$(this).data('min'), $(this).data('max')]);
            });
        });

        selector.on("click", "[data-table-nav]", function() {
            if ($(this).attr("data-table-nav") === "next") {
                selector.find('.footable-paging [data-page="next"] a').trigger("click");
            } else {
                selector.find('.footable-paging [data-page="prev"] a').trigger("click");
            }
        });
    });
});