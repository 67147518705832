$.lui("comp",".comp_gallery_selection",function (selector) {
    $.lib_flickity(function(){
       let carousel = selector.find("[data-carousel]");

       carousel.flickity({
           imagesLoaded: false,
           lazyLoad: true,
           percentPosition: false,
           setGallerySize: false,
           pageDots: false,
           wrapAround: false,
           contain: true,
           prevNextButtons: false,
           cellAlign: "left",
           draggable: false,
           accessibility: false
       });

       selector.on("mouseenter click", ".elm_nav_item", function () {
           carousel.flickity( 'select', $(this).index());
       });
    });
});