$.lui("comp",".comp_visual",function (selector) {
    selector.on("click","[data-scroll]",function () {
        let offset = 0;
        if(win.width() > 960) {
            offset = 70;
        }
        $('html, body').animate({
            scrollTop: selector.next("section, div").offset().top - offset
        }, 500);
    });

    if (navigator.userAgent.toLowerCase().indexOf("android") === -1 && (/iPhone/.test(navigator.userAgent) || /iPod/.test(navigator.userAgent)) === false) {
        selector.find("video")[0].play();
    }
});